import { applySnapshot, types } from 'mobx-state-tree'
import { editableModel } from '../helpers/editable'
import { Company } from './Company'
import { isValidNumberForRegion } from 'libphonenumber-js'
import { customerStatus } from '../dictionaries'
import { MomentDate } from '../types/Moment'
import { validateEmail } from '../helpers/validations'
import { ClearModel } from '../helpers/types'
import { BankAccount } from './BankAccount'
import { TradeCreditStatus } from '../dictionaries/TradeCreditStatus'
import { ICustomerSettings } from '../types/routes'
import { CustomerAccountType } from '../dictionaries/customerAccountType'

export const Contact = types.compose(
  types
    .model('Contact', {
      id: '',
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      invited: false,
      selected: false,
      status: types.optional(
        types.enumeration(Object.values(customerStatus)),
        customerStatus.new,
      ),
    })
    .views((self) => ({
      get name() {
        return self.first_name + ' ' + self.last_name
      },
      get isValidPhone() {
        return !!self.phone && isValidNumberForRegion(self.phone, 'US')
      },
      get isValidEmail() {
        /**
         * conditional, if email entered, it should validate.
         */
        return !!self.email && validateEmail(self.email)
      },
    }))
    .views((self) => ({
      get canSave() {
        return (
          !!self.first_name &&
          !!self.last_name &&
          self.isValidPhone &&
          self.isValidEmail
        )
      },
    })),
  editableModel(),
)

export const HouseCreditInfo = types.compose(
  types.model('HouseCreditInfo', {
    account_open_date: types.maybeNull(types.union(types.string, types.number)),
    max_credit_limit: types.maybeNull(types.union(types.string, types.number)),
    payment_history: types.maybeNull(types.union(types.string, types.number)),
    avg_days_to_pay: types.maybeNull(types.union(types.string, types.number)),
    open_a_r: types.maybeNull(types.union(types.string, types.number)),
    current_balance: types.maybeNull(types.union(types.string, types.number)),
    highest_credit: types.maybeNull(types.union(types.string, types.number)),
    billing_past_due_1: types.maybeNull(
      types.union(types.string, types.number),
    ),
    billing_past_due_2: types.maybeNull(
      types.union(types.string, types.number),
    ),
    billing_past_due_3: types.maybeNull(
      types.union(types.string, types.number),
    ),
    billing_past_due_more: types.maybeNull(
      types.union(types.string, types.number),
    ),
    total_past_due: types.maybeNull(types.union(types.string, types.number)),
    average_invoices: types.maybeNull(types.union(types.string, types.number)),
    rejected_ach_count: types.maybeNull(
      types.union(types.string, types.number),
    ),
    ein_ssn: types.optional(types.frozen(), ''),
  }),
  editableModel(),
)

export type IHouseCreditInfoModel = ClearModel<typeof HouseCreditInfo>

export const PastDueBreakdown = types.model('PastDueAmounts', {
  pastDue30: 0,
  pastDue60: 0,
  pastDue90: 0,
  pastDue120: 0,
  pastDue120Plus: 0,
})

export const CreditInfo = types.compose(
  types.model('CreditInfo', {
    credit_status: types.optional(
      types.enumeration(Object.values(TradeCreditStatus)),
      TradeCreditStatus.NotApplied,
    ),
    credit_status_valid_till: types.maybeNull(MomentDate),
    maximum_credit_amount: types.maybeNull(types.number),
    available_credit: types.maybeNull(types.number),
    held_amount: types.maybeNull(types.number),
    outstanding_amount: types.maybeNull(types.number),
    past_due_amount: types.maybeNull(types.number),
    processing_amount: types.maybeNull(types.number),
    past_due_breakdown: types.maybeNull(PastDueBreakdown),
  }),
  editableModel(),
)

export const customerProps = {
  id: '',
  company_id: '',
  name: types.maybeNull(types.string),
  first_name: types.maybeNull(types.string),
  last_name: types.maybeNull(types.string),
  display_name: types.maybeNull(types.string),
  phone: '',
  email: '',
  address: '',
  business_phone: '',
  invited: false,
  type: types.optional(
    types.enumeration(Object.values(CustomerAccountType)),
    CustomerAccountType.TradeCredit,
  ),
  status: types.optional(
    types.enumeration(Object.values(customerStatus)),
    customerStatus.saved,
  ),
  customer: types.maybeNull(Company),
  contacts: types.optional(types.array(Contact), [{}]),
  notes: '',
  dateAdded: '',
  last_purchase_date: types.maybeNull(MomentDate),
  house_credit_info: types.optional(HouseCreditInfo, {}),
  credit_info: types.maybeNull(CreditInfo),
  isImported: types.maybe(types.boolean),
  isDeleted: types.maybeNull(types.optional(types.boolean, false)),
  bankAccounts: types.optional(types.array(BankAccount), []),
  salesRepId: types.maybeNull(types.string),
  salesRepUserInfo: types.frozen(),
  isConnectorParent: types.maybe(types.boolean),
  settings: types.maybe(types.frozen<ICustomerSettings>()),
}

export const CustomerAccount = types.compose(
  types
    .model('CustomerAccount', customerProps)
    .views((self) => ({
      get isValidPhone() {
        return !!self.phone && isValidNumberForRegion(self.phone, 'US')
      },
      get isValidBusinessPhone() {
        return (
          (self.business_phone &&
            isValidNumberForRegion(self.business_phone, 'US')) ||
          !self.business_phone
        )
      },
      get isValidEmail() {
        /**
         * conditional, if email entered, it should validate.
         */
        return !self.email || validateEmail(self.email)
      },
      get contact() {
        const firstName = self.first_name?.trim() || ''
        const secondName = self.last_name?.trim() || ''

        const fullName = `${firstName} ${secondName}`.trim()
        const contactDisplayName = self.display_name?.trim() || ''
        if (fullName) {
          return fullName
        } else if (contactDisplayName) {
          return contactDisplayName
        }
        return ''
      },
      set contact(newName) {
        ;[self.first_name, self.last_name = ''] = newName.split(' ')
      },
    }))
    .views((self) => ({
      get canSave() {
        return (
          self.isValidPhone &&
          self.isValidBusinessPhone &&
          self.isValidEmail &&
          self.first_name &&
          self.last_name
        )
      },
      get canSaveContacts() {
        return self.contacts.every((contact) => contact.canSave)
      },
      get isCustomerPaymentMethodsAdded() {
        return !!self.bankAccounts?.length
      },
      get multipleMethodsExist() {
        return (self.bankAccounts?.length as number) > 1
      },
      get optionsData() {
        return self.bankAccounts?.map((c) => ({
          label: `${c.name} ${c.accountNumber.slice(-8)}`,
          value: c.id,
        }))
      },
    }))
    .views((self) => ({
      get canInvite() {
        return self.canSave && !self.invited && !self.customer
      },
      get isBillingContactAdded() {
        return !!self.contacts.find((c) => c.id)
      },
      get isSalesRepAdded() {
        return !!self.salesRepId
      },
      get isPristine() {
        return (
          !self.name &&
          !self.first_name &&
          !self.last_name &&
          !self.phone &&
          !self.email &&
          !self.address &&
          !self.business_phone &&
          !self.notes
        )
      },
    }))
    .actions((self) => ({
      resetWithPhoneNumber() {
        if (self.phone) {
          applySnapshot(self, { phone: self.phone.replace('+1', '') })
        }
      },
      addContact(contact: typeof Contact) {
        self.contacts.push(contact)
      },
      removeContact(index: number) {
        //self.contacts[index].status = customerStatus.deleted
        self.contacts.splice(index, 1)
      },
      onSalesRepChange(value: { _id: string; fullName: string }) {
        const { _id, fullName } = value
        self.salesRepId = _id
        self.salesRepUserInfo = { _id, fullName }
      },
    })),
  editableModel(),
)

export type ICustomerAccountModel = ClearModel<typeof CustomerAccount>
export type ICreditInfo = ClearModel<typeof CreditInfo> & {
  total_invoices_due?: number
  credit_terms?: string
}
